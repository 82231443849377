import { addHours, isAfter, isBefore, subMinutes } from "date-fns";

function isAppointmentOnTime(appointmentDate: Date) {
  const now = new Date();
  const isOnTime =
    isAfter(now, subMinutes(appointmentDate, 5)) &&
    isBefore(now, addHours(appointmentDate, 1));

  return isOnTime;
}

export { isAppointmentOnTime };
