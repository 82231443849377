import { createStackNavigator } from "@react-navigation/stack";

import { FunctionComponent } from "react";
import { AppointmentDetails } from "../pages/AppointmentDetails";
import { RegistrationData } from "../pages/RegistrationData";
import { ScheduleOnDemand } from "../pages/ScheduleOnDemand";
import { TabRoutes } from "./tab.route";
import { CompanyInfo } from "../pages/CompanyInfo";

const Stack = createStackNavigator();

const AppRoutes = () => {
  return (
    <Stack.Navigator
      initialRouteName="Application"
      screenOptions={{
        headerShown: false,
      }}>
      <Stack.Screen
        name="Application"
        component={TabRoutes}
        options={{ cardStyle: { backgroundColor: "#f00" } }}
      />

      <Stack.Screen
        name="Sobre empresa"
        component={CompanyInfo as FunctionComponent}
        options={{
          presentation: "modal",
          cardStyle: {
            backgroundColor: "#f00",
          },
        }}
      />

      <Stack.Screen
        name="Detalhes da consulta"
        component={AppointmentDetails as FunctionComponent}
        options={{
          presentation: "modal",
        }}
      />
      <Stack.Screen
        name="Atendimento Imediato"
        component={ScheduleOnDemand as FunctionComponent}
      />

      <Stack.Screen
        name="Dados Cadastrais"
        component={RegistrationData as FunctionComponent}
      />
    </Stack.Navigator>
  );
};

export { AppRoutes };
