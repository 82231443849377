import AsyncStorage from "@react-native-async-storage/async-storage";

import {
  collection,
  DocumentData,
  onSnapshot,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import React, {
  createContext,
  ReactNode,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { firestore } from "../config/firebase";
import { PropsReturn } from "../models/core.props-return";
import {
  authReducer,
  AuthReducerAction,
  AuthReducerActionKind,
  authReducerInitialState,
  AuthReducerState,
} from "../reducers/context/auth";

import Toast from "react-native-root-toast";
import jwt from "expo-jwt";

import { signUserInController } from "../controllers/auth/signUserInController";
import { updateNotificationTokenController } from "../controllers/notifcation/updateNotificationTokenController";
import { UserProps } from "../models/userProps";
import { getDependents } from "../services/firestore/user/getDependents";
import { formatISO } from "date-fns";

interface userDataProps {
  cpf: string;
  registration: string;
}

interface AuthContextProps {
  isSigned: boolean;
  loading: boolean;
  userToken: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  signIn: (props: userDataProps) => Promise<SignInPropsReturn>;
  signOut: () => Promise<PropsReturn>;
  state: AuthReducerState;
  dispatch: React.Dispatch<AuthReducerAction>;
  user: UserProps;
}

interface AuthContextComponentProps {
  children: ReactNode;
  loading: boolean;
  pushToken: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

interface SignInPropsReturn extends PropsReturn {
  data: DocumentData;
}

const AuthContext = createContext({} as AuthContextProps);

const AuthContextComponent = (props: AuthContextComponentProps) => {
  const { children, loading, setLoading, pushToken } = props;

  const [user, setUser] = useState<UserProps>({} as UserProps);

  const [userToken, setUserToken] = useState<string>("");

  const [state, dispatchAuthenticationState] = useReducer(
    authReducer,
    authReducerInitialState
  );

  useEffect(() => {
    const handleGetUserData = async () => {
      let userCPF: string | null = "";
      let userRegistration: string | null = "";

      try {
        userCPF = await AsyncStorage.getItem("RNAuth_userCPF");
        userRegistration = await AsyncStorage.getItem(
          "RNAuth_userRegistration"
        );

        if (!!userRegistration && !!userCPF) {
          const userQuery = query(
            collection(firestore, "users"),
            where("cpf", "==", userCPF),
            where("isActive", "==", true)
          );

          onSnapshot(userQuery, (querySnapshot) => {
            querySnapshot.docs.forEach(async (doc) => {
              const user: DocumentData = { ...doc.data() };

              if (
                user?.cpf === userCPF &&
                user?.registration === userRegistration
              ) {
                setLoading(true);
                const userToken = jwt.encode(
                  { companyId: user?.companyId, userId: user?.id },
                  process.env.AUTH_SECRET || ""
                );

                const dependents: any = await getDependents(user?.id);
                const birthdate =
                  user?.birthdate instanceof Timestamp
                    ? formatISO(user.birthdate?.toDate())
                    : user.birthdate || "";

                const updatedUser: UserProps = {
                  id: user?.id,
                  cpf: user?.cpf,
                  email: user?.email,
                  phone: user?.phone,
                  name: user?.name,
                  registration: user?.registration,
                  photoURL: user?.avatarUrl,
                  isCallEnabled: !!user?.isCallEnabled,
                  lastName: user?.lastName || "",
                  provider: user?.provider,
                  plan: user?.plan,
                  gender: user?.gender,
                  dependents: dependents.data,
                  companyId: user?.companyId,
                  birthdate,
                  notificationTokens: user?.notificationTokens || [],
                };

                setUserToken(`Bearer ${userToken}`);
                setUser(updatedUser);
                dispatchAuthenticationState({
                  type: AuthReducerActionKind.UPDATE_SIGN_IN,
                  isSignIn: true,
                });
              }
            });
          });
        }
        setLoading(false);
        return dispatchAuthenticationState({
          type: AuthReducerActionKind.UPDATE_USER_DATA,
          userCPF,
          userRegistration,
        });
      } catch (error) {
        console.error("[AuthContext.tsx] [useEffect] [getUser] ERROR", error);
        Toast.show("Não foi possível carregar os dados do usuário", {
          duration: Toast.durations.LONG,
        });
        return;
      }
    };

    handleGetUserData();
  }, []);

  const signIn = async (props: userDataProps): Promise<SignInPropsReturn> => {
    const { cpf, registration } = props;

    const signUserInResponse = await signUserInController({
      userData: { cpf, registration },
      dispatchAuthenticationState,
      setUser,
      setUserToken,
    });

    return signUserInResponse;
  };

  const signOut = async (): Promise<PropsReturn> => {
    try {
      setLoading(true);
      dispatchAuthenticationState({ type: AuthReducerActionKind.SIGN_OUT });
      setUser({} as UserProps);
      setUserToken("");

      await AsyncStorage.setItem("RNAuth_userCPF", "");
      await AsyncStorage.setItem("RNAuth_userRegistration", "");
      await AsyncStorage.setItem("RN_companyId", "");
      await AsyncStorage.setItem("RN_api_token", "");

      setLoading(false);
      return {
        message: "Usuário saiu da aplicação com sucesso!",
      };
    } catch (error: any) {
      return {
        message: "Não foi possível realizar o logout!",
        errorCode: error?.code,
        errorMessage: error?.message,
      };
    }
  };

  useEffect(() => {
    const handleSaveToken = async () => {
      await updateNotificationTokenController({
        pushToken,
        user,
      });
    };

    handleSaveToken();
  }, [user?.provider, pushToken]);

  const authValue = useMemo(
    () => ({
      isSigned: !!user.id,
      loading,
      setLoading,
      signIn,
      signOut,
      state,
      user,
      userToken,
      dispatch: dispatchAuthenticationState,
    }),
    [user]
  );

  return (
    <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
  );
};

export { AuthContext, AuthContextComponent };
