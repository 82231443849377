type SpecialtyIdToSpecialtyNameType = {
  [key: number]: string;
};

const specialtyIdToSpecialtyName: SpecialtyIdToSpecialtyNameType = {
  3: "Pediatria",
  4: "Clínica de Adultos",
  7: "Cardiologia",
  14: "Dermatologia",
  113: "Endocrinologia Pediátrica",
  15: "Endocrinologia",
  16: "Gastroenterologia",
  18: "Gerontologia",
  19: "Ginecologia",
  339: "Homeopatia",
  200: "Homeopatia Pediátrica",
  179: "Medicina de Família",
  26: "Neurologia",
  182: "Neuropediatria",
  34: "Nutrição (Licenciatura)",
  184: "Nutricionista",
  337: "Nutrição de Adultos",
  187: "Nutrição Pediátrica",
  132: "Ortopedia e Traumatologia",
  29: "Otorrinolaringologia",
  32: "Psiquiatria",
  33: "Psicologia",
  38: "Urologia",
};

export { specialtyIdToSpecialtyName };
