import SafeAreaView from "react-native-safe-area-view";

import { ParamListBase } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useEffect, useState } from "react";
import { StatusBar } from "react-native";
import { companyDefaultTheme } from "../../../../assets/theme/companyColors";
import { handleGetActiveAppointmentController } from "../../../controllers/appointment/handleGetActiveAppointmentController";
import { useAuth } from "../../../hooks/useAuth";
import { useCompany } from "../../../hooks/useCompany";
import { SpecialtyProps } from "../../../models/doc24/specialtyProps";
import { getColor } from "../../../styles/colors";
import { HeaderWithGoBack } from "../../Headers/HeaderWithGoBack";
import { Doc24ScheduleAppointment } from "../Doc24ScheduleAppointment";
import { SelectDoc24Specialties } from "../SelectDoc24Specialties";
import { styles } from "./styles";
interface Doc24ScheduleAppointmentManagerProps {
  navigation: NativeStackNavigationProp<ParamListBase, string, undefined>;
}

const Doc24ScheduleAppointmentManager = ({
  navigation,
}: Doc24ScheduleAppointmentManagerProps) => {
  const { company } = useCompany();
  const [isOnFistStep, setIsOnFirstStep] = useState<boolean>(true);
  const [selectedSpecialty, setSelectedSpecialty] = useState<SpecialtyProps>(
    {} as any
  );
  const [blockedSpecialties, setBlockedSpecialties] = useState<number[]>([]);

  const { user } = useAuth();

  const colors = getColor({ company: company ? company : companyDefaultTheme });

  useEffect(() => {
    const handleCheckAppointment = async () => {
      const response = await handleGetActiveAppointmentController({
        user,
        navigation,
        redirect: false,
      });

      const blockedSpecialtiesIds: any = response?.appointments?.map(
        (appointment) => appointment?.specialtyId
      );

      setBlockedSpecialties(blockedSpecialtiesIds);
    };

    navigation.addListener("focus", handleCheckAppointment);
  }, []);

  return (
    <SafeAreaView style={styles.container} forceInset={{ top: "always" }}>
      <StatusBar backgroundColor={colors.primaryColor} translucent={true} />
      <HeaderWithGoBack
        title="Agenda"
        description="Atendimento virtual"
        goBackFunction={() => {
          if (isOnFistStep) {
            navigation.goBack();
          } else {
            setIsOnFirstStep(true);
          }
        }}
      />
      {isOnFistStep ? (
        <SelectDoc24Specialties
          selectedSpecialty={selectedSpecialty}
          setSelectedSpecialty={setSelectedSpecialty}
          setIsSpecialtySelected={setIsOnFirstStep}
          blockedSpecialties={blockedSpecialties}
        />
      ) : (
        <Doc24ScheduleAppointment
          setIsOnFirstStep={setIsOnFirstStep}
          navigation={navigation}
          specialty={selectedSpecialty}
        />
      )}
    </SafeAreaView>
  );
};

export { Doc24ScheduleAppointmentManager };
