import { useState } from "react";

import { SafeAreaView } from "react-native-safe-area-context";
import { Text, View, Image, Linking, ScrollView } from "react-native";

import { NativeStackHeaderProps } from "@react-navigation/native-stack";

import { useCompany } from "../../hooks/useCompany";
import { HeaderWithGoBack } from "../../components/Headers/HeaderWithGoBack";

import { getColor } from "../../styles/colors";
import { styles } from "./styles";

const CompanyInfo = ({ navigation }: NativeStackHeaderProps) => {
  const [cardWidth, setCardWidth] = useState<number>(0);
  const { company } = useCompany();

  const cardBannerHeight = (cardWidth * 455) / 1000;

  const colors = getColor({ company });

  return (
    <SafeAreaView style={styles.container}>

      <HeaderWithGoBack
        goBackFunction={() => {
          navigation.navigate("Home");
        }}
        title={`Sobre ${company.name}`}
        icon="x"
      />
      <ScrollView>
        <View
          style={styles.contentWrapper}
          onLayout={(event) => {
            const { width } = event.nativeEvent.layout;

            setCardWidth(width);
          }}>
          <View>
            <Image
              style={[styles.cardBanner, { height: cardBannerHeight }]}
              source={{ uri: company.banner }}
              resizeMode="contain"
            />
          </View>
          <View style={styles.textWrapper}>
            <Text style={[styles.companyName, { color: colors.primaryColor }]}>
              {company.name}
            </Text>
            <View style={styles.descriptionWrapper}>
              {!!company.description ? (
                <>

                  <Text style={styles.descriptionText}>
                    {company.description}
                  </Text>
                </>
              ) : (
                <View style={styles.anyDescriptionWrapper}>
                  <Text style={styles.anyDescriptionText}> Em parceria com a Gleebem, {company.name} oferece os serviços de telemedicina para você. </Text>
                </View>
              )}
            </View>

            {!!company?.termsOfUse && (
              <View
                onTouchEnd={() => {
                  Linking.openURL(company.termsOfUse || "");
                }}
                hitSlop={{ top: 5, bottom: 5, right: 5 }}>
                <Text style={[styles.termsOfUse, { color: colors.primaryColor }]}>
                  Termos de uso {company.name || company.companyName}
                </Text>
              </View>
            )}

            <View style={[styles.descriptionWrapper, { marginTop: 10 }]}>
              <View style={styles.anyDescriptionWrapper}>
                {/* <MaterialCommunityIcons name="bird" size={24} color="#49A4CE" /> */}
                <Text style={styles.anyDescriptionText}>Somos uma empresa de tecnologia que fornece um
                  conjunto de serviços e benefícios que auxiliam nos
                  processos de saúde, bem-estar e proteção.
                </Text>
              </View>
            </View>

            <View
              onTouchEnd={() => {
                Linking.openURL("https://www.gleebem.com.br/termos_de_uso/");
              }}
              hitSlop={{ top: 5, bottom: 5, right: 5 }}>
              <Text style={[styles.termsOfUse, { color: colors.primaryColor }]}>
                Termos de uso da Gleebem
              </Text>
            </View>

            <View
              onTouchEnd={() => {
                Linking.openURL("https://www.gleebem.com.br/politica_de_privacidade/");
              }}
              hitSlop={{ top: 5, bottom: 5, right: 5 }}>
              <Text style={[styles.termsOfUse, { color: colors.primaryColor }]}>
                Política de Privacidade da Gleebem
              </Text>
            </View>

          </View>

          <Image
            style={[styles.gleebemLogo, { tintColor: colors.primaryColor }]}
            width={100}
            height={30}
            source={require("../../../assets/logo.png")}
            resizeMode="contain"
          />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export { CompanyInfo };
