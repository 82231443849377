const config = {
  API_KEY: "AIzaSyDG9nuzDfH4QL4usPlVElmHEPipgUAsvcw",
  AUTH_DOMAIN: "eu-sou-gleebem-dev.firebaseapp.com",
  PROJECT_ID: "eu-sou-gleebem-dev",
  STORAGE_BUCKET: "eu-sou-gleebem-dev.appspot.com",
  MESSAGING_SENDER_ID: "1004645240114",
  APP_ID: "1:1004645240114:web:d876e4402ffa4cd3139b2b",
  MEASUREMENT_ID: "G-M4DXMJ6HM8",

  QUEUE_ID: "sbYqeisvuKJHFGJKG5Cy",
  ACCESS_KEY: "FGu_QNSmy8dAnT8ykwP",
  DR_TIS_API: "https://vitalmedhomologacao.drtis.com.br",
  EXPO_PROJECT_ID: "64321a20-28b9-4b2b-8603-26581a3165ed",

  PUBLIC_VAPID:
    "BLPOX95C_WL7TYpdqNWh80w2QHb0ZxDmTiWXIRJIL9Y9fNPMasE9Ljc6m1OdT9IiujbrkF1hSmLANaDDVAj4qGs",

  ENVIRONMENT: "DEVELOPMENT",
};

export { config };
