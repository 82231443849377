import { TouchableOpacity, Text, TouchableOpacityProps } from "react-native";

import {
  AntDesign,
  FontAwesome,
  MaterialCommunityIcons,
} from "@expo/vector-icons";

import { SpecialtyProps } from "../../../models/doc24/specialtyProps";

import { styles } from "./styles";
import { useAuth } from "../../../hooks/useAuth";
import { getColor } from "../../../styles/colors";
import { companyDefaultTheme } from "../../../../assets/theme/companyColors";
import { useState } from "react";
import Toast from "react-native-root-toast";
import { useCompany } from "../../../hooks/useCompany";

interface SpecialtyCardProps extends TouchableOpacityProps {
  specialtyData: SpecialtyProps;
  icon: "heart" | "stethoscope" | "child" | "hospital";
  isSelected: boolean;
  handlePress: () => void;
  disabled?: boolean;
  disabledMessage?: string;
}

const SpecialtyCard = (props: SpecialtyCardProps) => {
  const { company } = useCompany();
  const {
    specialtyData,
    icon,
    handlePress,
    isSelected,
    disabled,
    disabledMessage,
  } = props;

  const colors = getColor({ company: company ? company : companyDefaultTheme });

  const SpecialtyIconsList = {
    heart: (
      <AntDesign
        name="heart"
        size={24}
        style={[styles.icon, disabled && { color: colors.grey }]}
        color={isSelected ? colors.white : colors.primaryColor}
      />
    ),
    stethoscope: (
      <FontAwesome
        name="stethoscope"
        size={24}
        style={[styles.icon, disabled && { color: colors.grey }]}
        color={isSelected ? colors.white : colors.primaryColor}
      />
    ),
    hospital: (
      <MaterialCommunityIcons
        name="hospital"
        size={24}
        style={[styles.icon, disabled && { color: colors.grey }]}
        color={isSelected ? colors.white : colors.primaryColor}
      />
    ),
    child: (
      <MaterialCommunityIcons
        name="baby-face-outline"
        size={24}
        style={[styles.icon, disabled && { color: colors.grey }]}
        color={isSelected ? colors.white : colors.primaryColor}
      />
    ),
  };
  return (
    <TouchableOpacity
      activeOpacity={0.7}
      style={[
        styles.container,
        {
          borderColor: isSelected ? colors.transparent : colors.primaryColor,
          backgroundColor: isSelected ? colors.primaryColor : colors.white,
        },
        disabled && {
          opacity: 0.7,
          borderColor: colors.grey,
          backgroundColor: colors.white,
        },
      ]}
      onPress={() => {
        if (disabled) {
          Toast.show(disabledMessage || "", {
            duration: Toast.durations.LONG,
          });
        } else {
          handlePress();
        }
      }}>
      {SpecialtyIconsList[icon]}
      <Text
        style={[
          styles.specialtyText,
          { color: isSelected ? colors.white : colors.primaryColor },
          disabled && { color: colors.grey },
        ]}>
        {specialtyData.specialty}
      </Text>
    </TouchableOpacity>
  );
};

export { SpecialtyCard };
