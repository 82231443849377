import { StyleSheet } from "react-native";

import { RFValue } from "react-native-responsive-fontsize";

import { getColor } from "../../styles/colors";
import { fonts } from "../../styles/fonts";

import { companyDefaultTheme } from "../../../assets/theme/companyColors";

const colors = getColor({ company: companyDefaultTheme });

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentWrapper: {
    display: "flex",
    width: "93.33%",
    height: "85%",
    minHeight: 300,
    borderRadius: 20,
    backgroundColor: colors.white,
    marginTop: 22,
    alignSelf: "center",
  },
  cardBanner: {
    width: "100%",
    height: "auto",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  textWrapper: {
    paddingHorizontal: 27,
    paddingVertical: 20,
  },
  companyName: {
    fontSize: RFValue(20),
    fontFamily: fonts.bold,
    color: colors.primaryColor,
    marginBottom: 22,
  },
  infoIcon: {
    fontSize: RFValue(17),
    color: colors.text,
  },
  descriptionHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 7,
  },
  descriptionTitle: {
    fontSize: RFValue(15),
    fontFamily: fonts.bold,
    color: colors.text,
    marginLeft: 9,
  },
  descriptionText: {
    fontSize: RFValue(12),
    fontFamily: fonts.medium,
    color: colors.text,
  },
  descriptionWrapper: {
    width: "100%",
    height: "auto",
    paddingHorizontal: 13,
    paddingTop: 7,
    paddingBottom: 32,
    borderWidth: 1,
    borderColor: colors.grey,
    borderRadius: 10,
  },
  termsOfUse: {
    textAlign: "center",
    color: colors.primaryColor,
    textDecorationLine: "underline",
    marginTop: 20,
  },
  anyDescriptionWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 20,
  },
  anyDescriptionIcon: {
    fontSize: RFValue(20),
    color: colors.text,
    marginRight: 10,
  },
  anyDescriptionText: {
    fontSize: RFValue(15),
    fontFamily: fonts.medium,
    color: colors.text,
  },
  gleebemLogo: {
    width: "35%",
    height: "10%",
    justifyContent: "center",
    alignContent: "center",
    alignSelf: "center",
  },
});

export { styles };
